import React from "react";
import { Context } from "./src/utils/useContext";
var prev = "";
var displayModal = 0;

export const onRouteUpdate = ({ prevLocation }) => {
    prev = prevLocation;
};
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === "undefined") {
        await import("intersection-observer");
    }
};
export const wrapPageElement = ({ element }) => {
    if (
        prev &&
        typeof window !== "undefined" &&
        localStorage.getItem("closedModal") !== "mailSent"
    ) {
        displayModal++;
    } else {
        return;
    }
    return <Context.Provider value={displayModal}>{element}</Context.Provider>;
};
